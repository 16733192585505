import React, { useState } from 'react'
import ImageComponent from '../Image/ImageComponent';
import { doPOST } from '../../util/httpUtil';

const ImageUpload = ({
    label = 'Upload new image',
    imgSrc = '',
    moduleId = '',
    module = '',
    onPostUpload = () => { },
    className = '',
    type = 'user',
    containerStyle = {}
}) => {
    let [image, setImage] = useState({
        file: null,
        reader: null
    });

    const onFileUpload = async (e) => {
        const selectedFile = e?.target?.files[0];
        setImage(prev => ({
            ...prev,
            file: selectedFile,
        }));

        if (selectedFile) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setImage(prev => ({
                    ...prev,
                    reader: fileReader.result,
                }));
            };
            fileReader.readAsDataURL(selectedFile);
        }
        if (!selectedFile) {
            return;
        }
        try {
            const formData = new FormData();
            formData.append('_id', moduleId);
            formData.append('module', module);
            formData.append('pic', selectedFile);
            const response = await doPOST('/v1/api/file/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.status === 200) {
                onPostUpload(response?.data?.data?.[0]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={`position-relative d-flex align-items-center ${className}`}>
            <div>
                <div
                    style={containerStyle}
                >
                    {image?.file ? <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={URL.createObjectURL(image?.file)} /> :
                        <ImageComponent source={imgSrc} style={{ width: "100%", height: "100%", objectFit: "cover" }} type={type} />}
                </div>
            </div>
            <div className="px-3">
                <div className="image-upload-consultant profile-edit cursor-pointer">
                    <label htmlFor="image">{label}</label>
                    <input hidden id='image' type="file" onChange={onFileUpload} />
                </div>
            </div>
        </div>
    )
}

export default ImageUpload;