import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import {
  InputField,
  SaveButton,
  SelectField,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import BannerService from "./BannerService";
import ImageComponent from "../../components/Image/ImageComponent";
import { checkImageType } from "../../util/helper";


const EditBanner = (props) => {
  const [data, setData] = useState({
    active: true,
    banner: null
  });

  const [loading, setLoading] = useState(null)

  const { id } = useParams();
  const { showError, showAlert, showMessage } = useContext(DialogContext);

  const getDetails = async () => {
    try {
      let response = {};
      if (id) response = await BannerService.get(id);
      setData(prev => ({ ...prev, ...(response?.data ?? []) }));
    } catch (error) {
      showError(error)
    }
  };


  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [id]);



  const status = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Inactive",
    },

  ];

  const submitForm = async () => {
    setLoading(true)
    try {
      if (!data?.name || !data?.navigateTo || data?.active === undefined) {
        showMessage('error', 'Please fill all required fields')
        setLoading(false)
        return;
      }

      const currentBannerFormData = new FormData()

      if (data?.name) {
        currentBannerFormData.append('name', data?.name)
      }

      if (data?.navigateTo) {
        currentBannerFormData.append('navigateTo', data?.navigateTo)
      }

      if (data?.banner) {
        if (!checkImageType(data?.banner)) {
          showMessage("info", "Please upload jpg, jpeg, png file only")
          return;
        }
        currentBannerFormData.append('banner', data?.banner);
      }

      if (data?.active != undefined) {
        currentBannerFormData.append('active', data?.active);
      }

      let response;
      if (id) {
        response = await BannerService.edit(currentBannerFormData, id);
      } else {
        response = await BannerService.save(currentBannerFormData);
      }
      getDetails();
      showAlert({
        title: id
          ? " Banner has been Updated Successfully"
          : "New Banner has been Created Successfully",
      });
    } catch (err) {
      showError(err?.message);
    }
    setLoading(false)
    setData({
      active: true,
      banner: null
    })
  };


  return (
    <React.Fragment>
      <Card
        className="mt-3"
        style={{
          width: "auto",
          height: "100%",
          overflow: "auto",
        }}
      >
        <CardBody
          className="py-3 px-5"
          style={{ top: "10%", position: "relative", width: "88%" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="px-4">
              <h2 style={{ fontWeight: "bold" }}>{data?.fname}</h2>
            </div>
          </div>
          <Row className="mt-3">
            <Col className="col-sm-6">
              <Label>Name <font color="#EC734B">*</font></Label>
              <Row>
                <InputField
                  className="col-sm-12"
                  required={true}
                  placeholder="Enter Name"
                  value={data?.name}
                  onChange={(v) => setData(prev => ({ ...prev, name: v }))}
                />
              </Row>
            </Col>
            <Col className="col-sm-6">
              <Label>Navigate To<font color="#EC734B">*</font></Label>
              <Row>
                <InputField
                  className="col-sm-12"
                  required={true}
                  placeholder="Navigate To"
                  value={data?.navigateTo}
                  onChange={(v) => setData(prev => ({ ...prev, navigateTo: v }))}
                />
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-sm-6 mt-2">
              <Label className="me-4">Banner</Label>
              <input type="file"
                name="myfile"
                onChange={(v) => {
                  if (v.target?.files?.length > 0) {
                    setData(prev => ({ ...prev, banner: v.target?.files[0], files: v.target.files }))
                  }
                }}
              />

              {
                data?.banner ?
                  <img
                    alt="Profile"
                    style={{ height: '140px', width: '140px', top: '50px', objectFit: 'contain' }}
                    src={URL.createObjectURL(data?.banner)} /> :
                  (
                    data?.url ? <ImageComponent source={data.url} style={{
                      height: "140px",
                      width: "140px",
                      objectFit: 'contain'
                    }} /> :
                      ""
                  )
              }
              {/* {data?.url ?
                <ImageComponent source={data.url} /> :
                ""
              } */}
            </Col>
            <Col className="col-sm-6">
            <Label>Status <font color="#EC734B">*</font></Label>
              <SelectField
                data={status}
                placeholder="Status"
                value={data?.active !== undefined ? data?.active : true}
                onChange={(v) => setData(prev => ({ ...prev, active: v.value }))}
              />
            </Col>
          </Row>
          <Row className="mt-5 d-flex justify-content-end">
            <SaveButton
              loading={loading}
              loadingColor="#FFCA59"
              style={{ right: "0%" }}
              onClick={submitForm}
              label={id ? "Update" : "Add"}
              disabled={data ? false : true}
            />
          </Row>
          <Row className="mt-5"></Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default observer(EditBanner);
