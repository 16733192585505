import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { DialogContext } from "../../store/context/DialogContext.js";
import { I18nContext } from "../../store/context/i18nContext.js";
import ConfigurationService from "./ConfigurationService.js";
// import "./style.css";
// import ConsultantDetails from "./ConsultantDetails";
import ImageComponent from "../../components/Image/ImageComponent.js"

import { Button, Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import {
    DateSelect,
    InputField,
    SaveButton,
    SelectField,
    PhoneNum,
    SelectFieldMultiple,
} from "../../components/index.js";
import CountrySelect from "../../components/CountrySelect/CountrySelect.js";
import Checkbox from "../Consultant/Checkbox.js";
import ImageUpload from "../../components/ImageUpload/index.js";

const Configuration = (props) => {
    const { t } = useContext(I18nContext);
    const { showError, showAlert } =
        useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const [searchbyName, setSearchbyName] = useState("");

    const [data, setData] = useState({})
    const [tempData, setTempData] = useState({})

    const updateData = (newData) => {
        setData(newData);
        submitForm(newData)
    }
    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            let filter = {};
            let query = '';
            if (searchbyName) {
                filter.name = searchbyName;
                query = `?name=${searchbyName}`
            }
            const response = await ConfigurationService.grid(query);
            setData(response?.data)
            setTempData(response?.data)
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    const submitForm = async (data) => {
        try {
            let response;
            response = await ConfigurationService.edit(data);
            console.log(response);
            fetchData();
            showAlert({
                title: "New Consultant has been Created Successfully",
                description:
                    "The details have been sent to the respective consultant's Email Address",
            });
            // showMessage("info", "Consultant saved successfully");
        } catch (err) {
            showError(err?.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    let navigate = useNavigate();
    return (
        <React.Fragment>
            <Card
                className="mt-3"
                style={{
                    height: "100%",
                    overflow: "auto",
                }}
            >
                <CardBody
                    className="py-3 px-5"
                    style={{ top: "10%", position: "relative", width: "88%" }}
                >
                    <Row className="mt-3">
                        <Col className="col-sm-2">
                            <Label>ios SoftwareUpdate</Label>
                            <Row>
                                <InputField
                                    required={true}
                                    placeholder="Enter iosSoftUpdate"
                                    type="text"
                                    value={tempData?.iosSoftUpdate}
                                    onChange={(v) => setTempData({ ...tempData, iosSoftUpdate: v })}
                                />
                            </Row>
                        </Col>
                        <Col className="col-sm-2 ">
                            <Label>ios HardUpdate</Label>
                            <Row>
                                <InputField
                                    required={true}
                                    type="text"
                                    placeholder="Enter iosHardUpdate"
                                    value={tempData?.iosHardUpdate}
                                    onChange={(v) => setTempData({ ...tempData, iosHardUpdate: v })}
                                />
                            </Row>
                        </Col>
                        <Col className="col-sm-2">
                            <Label>Android SoftUpdate</Label>
                            <InputField
                                required={true}
                                type="text"
                                placeholder="Enter androidSoftUpdate"
                                value={tempData?.androidSoftUpdate}
                                onChange={(v) => setTempData({ ...tempData, androidSoftUpdate: v })}
                            />
                        </Col>
                        <Col className="col-sm-2 ">
                            <Label>Android HardUpdate</Label>
                            <InputField
                                required={true}
                                type="text"
                                placeholder="Enter androidHardUpdate"
                                value={tempData?.androidHardUpdate}
                                onChange={(v) => setTempData({ ...tempData, androidHardUpdate: v })}
                            />
                        </Col>

                        <Col className="col-sm-2">
                            <Label>PlayStore Url</Label>
                            <InputField
                                placeholder="Enter playStoreUrl"
                                type="text"
                                value={tempData?.playStoreUrl}
                                onChange={(v) => setTempData({ ...tempData, playStoreUrl: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>AppStore Url</Label>
                            <InputField
                                placeholder="Enter appStoreUrl"
                                type="text"
                                value={tempData?.appStoreUrl}
                                onChange={(v) => setTempData({ ...tempData, appStoreUrl: v })}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="col-sm-2">
                            <Label>AWS Url</Label>
                            <InputField
                                placeholder="Enter awsUrl"
                                type="text"
                                value={tempData?.awsUrl}
                                onChange={(v) => setTempData({ ...tempData, awsUrl: v })}
                            />
                        </Col>

                        <Col className="col-sm-2">
                            <Label>ios SoftUpdate Consultant</Label>
                            <InputField
                                placeholder="Enter iosSoftUpdateConsultant"
                                type="text"
                                value={tempData?.iosSoftUpdateConsultant}
                                onChange={(v) => setTempData({ ...tempData, iosSoftUpdateConsultant: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>ios HardUpdate Consultant</Label>
                            <InputField
                                placeholder="Enter iosHardUpdateConsultant"
                                type="text"
                                value={tempData?.iosHardUpdateConsultant}
                                onChange={(v) => setTempData({ ...tempData, iosHardUpdateConsultant: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>Android SoftUpdate Consultant</Label>
                            <InputField
                                placeholder="Enter androidSoftUpdateConsultant"
                                type="text"
                                value={tempData?.androidSoftUpdateConsultant}
                                onChange={(v) => setTempData({ ...tempData, androidSoftUpdateConsultant: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>Android HardUpdate Consultant</Label>
                            <InputField
                                placeholder="Enter androidHardUpdateConsultant"
                                type="text"
                                value={tempData?.androidHardUpdateConsultant}
                                onChange={(v) => setTempData({ ...tempData, androidHardUpdateConsultant: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>PlayStore Url Consultant</Label>
                            <InputField
                                placeholder="Enter playStoreUrlConsultant"
                                type="text"
                                value={tempData?.playStoreUrlConsultant}
                                onChange={(v) => setTempData({ ...tempData, playStoreUrlConsultant: v })}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="col-sm-2">
                            <Label>AppStore Url Consultant</Label>
                            <InputField
                                placeholder="Enter appStoreUrlConsultant"
                                type="text"
                                value={tempData?.appStoreUrlConsultant}
                                onChange={(v) => setTempData({ ...tempData, appStoreUrlConsultant: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>ios SoftUpdate User</Label>
                            <InputField
                                placeholder="Enter iosSoftUpdateUser"
                                type="text"
                                value={tempData?.iosSoftUpdateUser}
                                onChange={(v) => setTempData({ ...tempData, iosSoftUpdateUser: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>ios HardUpdate User</Label>
                            <InputField
                                placeholder="Enter iosHardUpdateUser"
                                type="text"
                                value={tempData?.iosHardUpdateUser}
                                onChange={(v) => setTempData({ ...tempData, iosHardUpdateUser: v })}
                            />
                        </Col>

                        <Col className="col-sm-2">
                            <Label>Android SoftUpdate User</Label>
                            <InputField
                                placeholder="Enter androidSoftUpdateUser"
                                type="text"
                                value={tempData?.androidSoftUpdateUser}
                                onChange={(v) => setTempData({ ...tempData, androidSoftUpdateUser: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>Android HardUpdate User</Label>
                            <InputField
                                placeholder="Enter androidHardUpdateUser"
                                type="text"
                                value={tempData?.androidHardUpdateUser}
                                onChange={(v) => setTempData({ ...tempData, androidHardUpdateUser: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>PlayStore Url User</Label>
                            <InputField
                                placeholder="Enter playStoreUrlUser"
                                type="text"
                                value={tempData?.playStoreUrlUser}
                                onChange={(v) => setTempData({ ...tempData, playStoreUrlUser: v })}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="col-sm-2">
                            <Label>AppStore Url User</Label>
                            <InputField
                                placeholder="Enter appStoreUrlUser"
                                type="text"
                                value={tempData?.appStoreUrlUser}
                                onChange={(v) => setTempData({ ...tempData, appStoreUrlUser: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>Offline Query Rate</Label>
                            <InputField
                                placeholder="Enter offlineQueryRate"
                                type="Number"
                                value={tempData?.offlineQueryRate}
                                onChange={(v) => setTempData({ ...tempData, offlineQueryRate: v })}
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Label>Consultant Profile Background Color</Label>
                            <InputField
                                placeholder="Consultant Profile Background Color"
                                value={tempData?.imageBackgroundColor}
                                onChange={(v) => setTempData({ ...tempData, imageBackgroundColor: v })}
                            />
                        </Col>

                        <Col className="col-sm-4">
                            <ImageUpload
                                label="Upload new image"
                                imgSrc={tempData?.imageBackground}
                                moduleId={tempData?._id}
                                module="configuration"
                                onPostUpload={(awsUrl) => {
                                    setTempData({ ...tempData, imageBackground: awsUrl });
                                }}
                                type="default"
                                containerStyle={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                }}
                            />
                        </Col>
                        <Col className="col-sm-5 mt-4">
                            <Checkbox
                                checked={tempData?.isWebinarActive}
                                label="Webinar Active"
                                onClick={(v) => setTempData({ ...tempData, isWebinarActive: v.target.checked })}
                            />
                        </Col>
                        <Col className="col-sm-5 mt-4">
                            <Checkbox
                                checked={tempData?.isMindfulActive}
                                label="Mindful Active"
                                onClick={(v) => setTempData({ ...tempData, isMindfulActive: v.target.checked })}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3 d-flex justify-content-end">
                        <SaveButton
                            style={{ right: "0%" }}
                            onClick={() => updateData(tempData)}
                            label={"Update"}
                        />
                    </Row>

                </CardBody>
            </Card>
        </React.Fragment >
    );
};

export default observer(Configuration);
