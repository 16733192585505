import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/httpUtil";
import { ENDPOINTS } from "./FreeChatConstant";

class Service {
    records = [];
    totalRecords = 0;
    page = 1;
    rows = 40;
    filterOption = {};

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (filter) {
        if (filter !== this.filterOption) {
            this.page = 1
            this.rows = 40
        }
        this.filterOption = filter;
        const response = await doGET(ENDPOINTS.grid(`?rows=${this.rows}&page=${this.page}`));
        if (response.status === 200) {
            this.records = response?.data?.data?.rows;
            this.totalRecords = response.data?.data?.total
            return response?.data?.data?.rows;
        }
        return response?.data
    };

    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        await this.fetch(this.filterOption);
    };

    create = async function (data) {
        const response = await doPOST(ENDPOINTS.create, data);
        this.fetch();
        return response;
    };

    edit = async function (data) {
        const response = await doPUT(ENDPOINTS.update, data);
        this.fetch();
        return response;
    };

    delete = async function (id) {
        const response = await doDELETE(ENDPOINTS.delete((id)));
        this.fetch();
        return response;
    };

    detail = async function (id) {
        const response = await doGET(ENDPOINTS.detail((id)));
        return response?.data?.data ?? {};
    };
}

const FreeChatService = new Service();
export default FreeChatService;