import React, { useContext, useEffect, useState } from "react"
import revenueimg from "../../assets/images/revenue.png"
import orderimg from "../../assets/images/orders.png"
import growthimg from "../../assets/images/growth.png"
import salesimg from "../../assets/images/sales.png"
import linechart from "../../assets/images/Linechart.png"
import Charts from "./Charts"
import PaymentChart from "./LineChart"
import PieChart from "./PieChart"
import { Layout } from "../../components"
import { Th, Thead, Tr } from "react-super-responsive-table"
import BarChart from "./StackedBarChart"
import DashboardService from './DashboardService'
import { DialogContext } from "../../store/context/DialogContext"
import DateSelect from "../../components/DateSelects/DateSelect"
import moment from "moment";
import DashboardCard from '../../components/DashboardCard';
import DateRange from "../../components/DateSelects/DateRange"
import {
  CardBody,
  Card,
  Col,
  Row,
  Label,
} from "reactstrap";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import CountsRow from "./CountRow"
import ConsultantEarnings from "../../components/DashboardCard/ConsultantEarnings"
import TopupStats from "./TopupStats"
import OrdersGrid from "./Orders/OrdersGrid"
import RevenueGrid from "./Revenue/RevenueGrid"
import Analytics from "../Analytics/Analytics"
import { useNavigate } from "react-router-dom"
import ConsultantService from "../Consultant/ConsultantService"

const Dashboard = ({ showChart,
  showTotal = false,
  showDateSelect,
  showMonthSelect,
  title = "",
  placeholder = "",
  onChange = () => { },
  chartType = 'doughnut',
  // showError,
  statsType, }) => {


  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showOrders, setShowOrders] = useState(false);
  const [showRevenue, setShowRevenue] = useState(false);
  const [data, setData] = useState({
    tripsCompletedStats: {},
    currentMonthInvoice: {},
    lastMonthInvoice: {},
    employeeStats: {},
    yearlyInvoice: {},
    halfYearlyInvoice: [],
    tripsToday: {},
    monthlyTrips: {},
    last10daysTrip: {},
    lastSixMonthLabels: [],
    last12MonthLabels: [],
    vehicleStats: {
      total: 0,
      totalActive: 0,
      totalUnderMaintenance: 0,
      totalSold: 0,
      permitExpiredIn15Days: 0,
      permitExpiredIn30Days: 0,
      permitExpired: 0,
    },
    invoice: {
      data: [],
      labels: [],
      monthFiler: {
        shown: true,
        value: moment().format('YYYYMM')
      }
    },
    trips: {
      data: [],
      labels: [],
      dateFilter: {
        shown: true,
        value: moment().format('YYYYMMDD')
      }
    },
    selectedMonth: {
      paymentByTrips: parseInt(moment().format('YYYYMM')),
      monthlyTrips: parseInt(moment().format('YYYYMM')),
      invoice: parseInt(moment().format('YYYYMM')),
    },
    sortUsersBy: {
      users: "",
      monthly: { label: moment().format('MMM'), value: moment().format('MMM') },
    },
    sortMonthBy: {
      trips: "",
      month: { label: moment().format('MMM'), value: moment().format('MMM') },
    }
  });


  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    BarElement,
    Title,);

  const [salesData, setSalesData] = useState({})
  const [revenueGraphData, setRevenueGraphData] = useState({})
  const [earningsData, setEarningData] = useState([])
  const [walletData, setWalletData] = useState([])
  const [revenueData, setRevenueData] = useState([])
  const [ordersData, setOrdersData] = useState([])
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYYMMDD'))
  const [tillDate, setTillDate] = useState(moment().format('YYYYMMDD'))
  const [chartData, setChartData] = useState({});

  const [filter, setFilter] = useState({})


  const fetchSalesData = async () => {
    if (loading) return;
    setLoading(true);
    let filter = {};
    filter.fromDate = fromDate || moment().format('YYYYMMDD');
    filter.tillDate = tillDate || moment().format('YYYYMMDD');
    try {
      const response = await DashboardService.getSales(filter);
      const inputArray = response?.data;
      // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      const x_axis_data = inputArray.map(item => item.totalAmount);

      const options = {
        scales: {
          x: {
            type: "category",
            title: {
              display: true,
              text: "Date",
              font: {
                size: 12,
                weight: "bold",
                color: "black"
              }
            }
          },
          y: {
            title: {
              display: true,
              text: "Amount (AED)",
              font: {
                size: 12,
                weight: "bold",
                color: "black"
              }
            }
          }
        }
      };


      const data = {
        labels: inputArray.map(item => item._id),
        datasets: [
          {
            fill: true,
            label: 'sales',
            data: x_axis_data,
            borderColor: "rgb(242, 140, 40)",
            backgroundColor: 'rgba(242, 140, 40, 0.2)',
          },
        ],
      };
      setSalesData({ data: data, options: options });
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  const fetchRevenueGraphData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      filter.fromDate = fromDate || moment().format('YYYYMMDD');
      filter.tillDate = tillDate || moment().format('YYYYMMDD');
      const response = await DashboardService.getPayments(filter);
      const inputArray = response?.data;
      // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      const x_axis_data = inputArray.map(item => item.totalAmount);

      const options = {
        scales: {
          x: {
            type: "category",
            title: {
              display: true,
              text: "Date",
              font: {
                size: 12,
                weight: "bold",
                color: "black"
              }
            }
          },
          y: {
            title: {
              display: true,
              text: "Amount (AED)",
              font: {
                size: 12,
                weight: "bold",
                color: "black"
              }
            }
          }
        }
      };

      const data = {
        labels: inputArray.map(item => item._id),
        datasets: [
          {
            fill: true,
            label: 'revenue',
            data: x_axis_data,
            borderColor: 'rgb(0, 128, 0)',
            backgroundColor: 'rgba(0, 128, 0, 0.2)',
          },
        ],
      };
      setRevenueGraphData({ data: data, options: options });
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  const fetchEarningsData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      filter.fromDate = fromDate || moment().format('YYYYMMDD');
      filter.tillDate = tillDate || moment().format('YYYYMMDD');
      const response = await DashboardService.earnings(filter);
      const inputArray = response?.earnings;
      setEarningData(inputArray.toFixed(2));
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  const fetchWalletAmount = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      filter.fromDate = fromDate || moment().format('YYYYMMDD');
      filter.tillDate = tillDate || moment().format('YYYYMMDD');
      const response = await DashboardService.walletAmount(filter);
      const inputArray = response?.data?.balance;
      setWalletData(inputArray.toFixed(2));
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  const fetchRevenueData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      filter.fromDate = fromDate || moment().format('YYYYMMDD');
      filter.tillDate = tillDate || moment().format('YYYYMMDD');
      const response = await DashboardService.revenue(filter);
      const inputArray = response?.revenue;
      setRevenueData(inputArray.toFixed(2));
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  const fetchOrdersData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      filter.fromDate = fromDate || moment().format('YYYYMMDD');
      filter.tillDate = tillDate || moment().format('YYYYMMDD');
      const response = await DashboardService.orders(filter);
      const inputArray = response?.orders;
      setOrdersData(inputArray);
    } catch (e) {
      showError(e);
    }

    setLoading(false);
  };

  const getMindfulArray = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await ConsultantService.getmindfulPlans()
    } catch (e) {
      showError(e);
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    getMindfulArray();
  }, [])
  


  useEffect(() => {
    fetchSalesData()
    fetchEarningsData()
    fetchOrdersData()
    fetchRevenueData()
    fetchRevenueGraphData()
    fetchWalletAmount()
    // fetchOrderStats()
    // fetchCustomerExpenditure()
    // fetchConsultantEarnings()
  }, [fromDate, tillDate])

  const mergeGraphData = (salesData, revenueGraphData) => {
    if (!salesData?.data || !revenueGraphData?.data) return null;
    const labels = salesData.data.labels || revenueGraphData.data.labels;
    const mergedData = {
      labels: labels,
      datasets: [
        ...salesData.data.datasets.map((dataset) => ({
          ...dataset,
          label: dataset.label || 'Sales',
          borderColor: dataset.borderColor || '#FFCA59',
          backgroundColor: dataset.backgroundColor || 'rgba(255, 202, 89, 0.5)',
        })),
        ...revenueGraphData.data.datasets.map((dataset) => ({
          ...dataset,
          label: dataset.label || 'Revenue',
          borderColor: dataset.borderColor || '#4A3AFF',
          backgroundColor: dataset.backgroundColor || '#C893FD',
        })),
      ],
    };

    return { data: mergedData, options: salesData?.options || revenueGraphData?.options };
  };

  useEffect(() => {
    if (!!revenueGraphData && !!salesData) {
      const mergedDataSet = mergeGraphData(salesData, revenueGraphData)
      setChartData(mergedDataSet)
    }
  }, [salesData, revenueGraphData])

  return (
    <React.Fragment>
      <div className="page-content mx-3 ">
        <Row className=" p-2  my-2  ms-0 me-3" style={{
          position: "relative", backgroundColor: "white", borderRadius: "4px",
        }}>
          <Col className="col-md-4 col-12 ">
            <div className="d-flex ">
              <DateRange
                placeholder={["From", "To"]}
                allowClear={false}
                defaultValue={[fromDate, tillDate]?.filter(date => date)?.map(date => moment(parseInt(date), 'YYYYMMDD'))}
                // defaultValue={[null, null]}
                disabled={[false, false]}
                onChange={(v) => {
                  if (v === null) {
                    setFromDate(null);
                    setTillDate(null);
                  } else {
                    const formattedFromDate = moment.unix(v[0]).format('YYYYMMDD');
                    const formattedTillDate = moment.unix(v[1]).format('YYYYMMDD');
                    setFromDate(formattedFromDate)
                    setTillDate(formattedTillDate)
                  }
                }}
              />
              {/* <div className="d-flex ">
                <Label style={{ marginRight: "10px", marginTop: "10px" }}>From : </Label>
                <DateSelect
                  placeholder="Select Date"
                  value={fromDate}
                  onChange={(v) => setFromDate((v))}
                />
              </div>
              <div className="d-flex ms-3">
                <Label style={{ marginRight: "10px", marginTop: "10px" }}>To : </Label>
                <DateSelect
                  placeholder="Select Date"
                  value={tillDate}
                  onChange={(v) => setTillDate((v))}
                />
              </div> */}
            </div>
          </Col>
        </Row>
        <div style={{ maxHeight: "100vh", overflow: "scroll" }}>
          <Card
            style={{
              height: "20%",
              position: "relative",
            }}>
            <CardBody>
              <div className="d-flex justify-content-between ">
                <div className="d-flex" style={{ cursor: 'pointer' }} onClick={() => navigate("/customer")}>
                  <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                    {/* <img alt=" " src={revenueimg} height="30" width="30" /> */}
                    <i className="bx bx-wallet" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                  </div>
                  <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                    <strong>
                      AED {walletData}
                    </strong>
                    <br />
                    <h4>
                      Wallet
                    </h4>
                  </h2>
                </div>
                <div className="d-flex">
                  <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                    <img alt=" " src={revenueimg} height="30" width="30" />
                  </div>
                  <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                    <strong>
                      AED {earningsData}
                    </strong>
                    <br />
                    <h4>
                      Consultant Earnings (Selected Date Range)
                    </h4>
                  </h2>
                </div>
                <div className="d-flex" style={{ cursor: 'pointer' }} onClick={() => setShowRevenue(true)}>
                  <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                    <img alt=" " src={growthimg} height="30" width="30" />
                  </div>
                  <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                    <strong>
                      AED {revenueData}
                    </strong>
                    <br />
                    <h4>
                      Revenue  (Selected Date Range)
                    </h4>
                  </h2>
                </div>
                <div className="d-flex" style={{ cursor: 'pointer' }} onClick={() => setShowOrders(true)}>
                  <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                    <img alt=" " src={orderimg} height="30" width="30" />
                  </div>
                  <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                    <strong>
                      {ordersData}
                    </strong>
                    <br />
                    <h4>
                      Orders
                    </h4>
                  </h2>
                </div>
                {/* <div className="d-flex">
                <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px" }}>
                  <img alt=" " src={salesimg} height="30" width="30" />
                </div>
                <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                  <strong>
                    Rs . 12,00,0000
                  </strong>
                  <br />
                  <h4>
                    Sales 13.5
                  </h4>
                </h2>
              </div> */}
              </div>
            </CardBody>
            <CountsRow fromDate={fromDate} tillDate={tillDate} />
          </Card>
          <Row>
            <Col className="col-md-6 col-12"  >
              <DashboardCard
                statsType="orders"
                style={{ height: "30%", }}
                title="Orders"
                total={4}
                showError={showError}
              />
            </Col>
            <Col className="col-md-6 col-12" >
              <ConsultantEarnings />
            </Col>
            <Col className="col-md-6 col-12" >
              <DashboardCard
                statsType="expenditure"
                style={{ height: "30%", maxHeight: "fit-content", }}
                showTotal
                title="Customer Expenditure"
                total={4}
                showError={showError}
              />
            </Col>
          </Row>
          <Card style={{ height: "30%", }}>
            <CardBody>
              <h4>
                <strong>
                  Sales & Revenue
                </strong>
              </h4>
              {/* <Charts data={salesData?.data} options={salesData?.options} /> */}
              <Charts data={chartData?.data} options={chartData?.options} />
            </CardBody>
          </Card>

          <Card style={{ height: "30%", }}>
            <CardBody>
              <h4><strong>Topups</strong></h4>
              <TopupStats fromDate={fromDate} tillDate={tillDate} />
            </CardBody>
          </Card>

          <div style={{ marginBottom: 120 }}>
            <Analytics fromDate={fromDate} tillDate={tillDate} />
          </div>
        </div>

        <OrdersGrid visible={showOrders} setVisible={setShowOrders} fromDate={fromDate} tillDate={tillDate} />
        <RevenueGrid visible={showRevenue} setVisible={setShowRevenue} fromDate={fromDate} tillDate={tillDate} />
      </div>
    </React.Fragment >
  )
}

export default Dashboard