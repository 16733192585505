export const ENDPOINTS = {
    grid: (query = '') => `/v1/api/free-chat/grid${query}`,
    update: "/v1/api/free-chat/update",
    detail: (id = '') => `/v1/api/free-chat/${id}/detail`,
    delete: (id = '') => `/v1/api/free-chat/${id}/delete`,
    create: "/v1/api/free-chat/create",
}

export function formatSecondsToReadableFormat(seconds) {
    if ([null, undefined, 0, false, ""]?.includes(seconds)) return ""
    if (seconds < 60) {
        return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else {
        const days = Math.floor(seconds / 86400);
        return `${days} day${days !== 1 ? 's' : ''}`;
    }
}

export const TYPE_MAP = {
    1: "FIRST FREE CHAT"
};

export const Types = [
    { label: "FIRST FREE CHAT", value: 1 }
];