import React, { useEffect, useState } from 'react'
// import DateSelect from '../DateSelects/DateSelect';
import { Card, Col, Row } from 'reactstrap';
import { t } from 'i18next';
import { COLOR_MAP } from './DashboardConstant';
// import DoughnutChart from '../Charts/DoughnutChart';
import CountCard from './CountCard';
import moment from 'moment';
import DashboardService from '../../pages/Dashboard/DashboardService';
import DateRange from '../DateSelects/DateRange';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';

// import MonthPicker from '../Dropdowns/MonthSelect/MonthPicker';
// import LineChart from '../Charts/LineChart';

//get Label and dataSetFrom data using key value pair


function DashboardCard({
    showChart,
    showTotal = false,
    showDateSelect = false,
    title = "",
    showMonthSelect = false,
    showError,
    statsType,
    style,
}) {


    const [loading, setLoading] = useState(false);

    const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYYMMDD'))
    const [tillDate, setTillDate] = useState(moment().format('YYYYMMDD'))
    const [data, setData] = useState({
        consultantEarnings: {
            Chat: { label: "Chat", value: {} },
            VoiceCall: { label: "Voice Call", value: {} },
            VideoCall: { label: "Video Call", value: {} },
        },
        customerExpenditure: {
            Chat: { label: "Chat", value: {} },
            VoiceCall: { label: "Voice Call", value: {} },
            VideoCall: { label: "Video Call", value: {} },
        },
        orderStats: {
            Chat: {
                label: "Chat",
                value: {
                    // Missed: 0,
                    // Connected: 0,
                    // Duration: 0,
                },
            },
            VoiceCall: {
                label: "Voice Call",
                value: {
                    // Missed: 0,
                    // Connected: 0,
                    // Duration: 0,
                },
            },
            VideoCall: {
                label: "Video Call",
                value: {
                    // Missed: 0,
                    // Connected: 0,
                    // Duration: 0,
                },
            },
        },
        selectedDates: {
            orders: {
                fromDate: parseInt(moment().subtract(1,'month').format('YYYYMMDD')),
                tillDate: parseInt(moment().format('YYYYMMDD')),
            },
            earnings: {
                fromDate: parseInt(moment().subtract(1,'month').format('YYYYMM')),
                tillDate: parseInt(moment().format('YYYYMM')),
            },
            expenditure: {
                fromDate: parseInt(moment().subtract(1,'month').format('YYYYMM')),
                tillDate: parseInt(moment().format('YYYYMM')),
            },
        }
    });


    // const fetchConsultantEarnings = async (fromDate, tillDate) => {
    //     if (loading) return;
    //     setLoading(true);
    //     try {
    //         let filter = {};
    //         filter.fromDate = fromDate || moment().subtract(1, 'months').format('YYYYMMDD');
    //         filter.tillDate = tillDate || moment().format('YYYYMMDD');
    //         const response = await DashboardService.consultantEarnings(filter);
    //         setData({
    //             ...data,
    //             consultantEarnings: {
    //                 ...data?.consultantEarnings,
    //                 Chat: {
    //                     ...data?.consultantEarnings?.Chat,
    //                     value: response?.Chat
    //                 },
    //                 VideoCall: {
    //                     ...data?.consultantEarnings?.VideoCall,
    //                     value: response?.VideoCall
    //                 },
    //                 VoiceCall: {
    //                     ...data?.consultantEarnings?.VoiceCall,
    //                     value: response?.VoiceCall
    //                 },
    //             }
    //         })
    //     } catch (e) {
    //         showError(e);
    //     }
    //     setLoading(false);
    // };
    const fetchCustomerExpenditure = async (fromDate, tillDate) => {
        if (loading) return;
        setLoading(true);
        try {
            let filter = {};
            filter.fromDate = fromDate || moment().subtract(1, 'months').format('YYYYMMDD');
            filter.tillDate = tillDate || moment().format('YYYYMMDD');
            const response = await DashboardService.customerExpenditure(filter);
            setData({
                ...data,
                customerExpenditure: {
                    ...data?.customerExpenditure,
                    Chat: {
                        ...data?.customerExpenditure?.Chat,
                        value: response?.Chat
                    },
                    VideoCall: {
                        ...data?.customerExpenditure?.VideoCall,
                        value: response?.VideoCall
                    },
                    VoiceCall: {
                        ...data?.customerExpenditure?.VoiceCall,
                        value: response?.VoiceCall
                    },
                }
            })
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };
    const fetchOrderStats = async (fromDate, tillDate) => {
        if (loading) return;
        setLoading(true);
        try {
            let filter = {};
            filter.fromDate = fromDate || moment().subtract(1, 'months').format('YYYYMMDD');
            filter.tillDate = tillDate || moment().format('YYYYMMDD');
            const response = await DashboardService.orderStats(filter);
            setData({
                ...data,
                orderStats: {
                    ...data?.orderStats,
                    Chat: {
                        ...data?.orderStats?.Chat,
                        value: response?.Chat
                    },
                    VideoCall: {
                        ...data?.orderStats?.VideoCall,
                        value: response?.VideoCall
                    },
                    VoiceCall: {
                        ...data?.orderStats?.VoiceCall,
                        value: response?.VoiceCall
                    },
                }
            })
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };


    useEffect(() => {
        let startDate = null;
        let endDate = null;
        switch (statsType) {
            case "orders":
                startDate = data?.selectedDates?.orders?.fromDate || moment().startOf('month').format('YYYYMMDD');
                endDate = data?.selectedDates?.orders?.tillDate || moment().endOf('month').format('YYYYMMDD');
                fetchOrderStats(startDate, endDate);
                break;
            case "expenditure":
                startDate = data?.selectedDates?.orders?.fromDate || moment().startOf('month').format('YYYYMMDD');
                endDate = data?.selectedDates?.orders?.tillDate || moment().endOf('month').format('YYYYMMDD');
                fetchCustomerExpenditure(startDate, endDate);
                break;
            default:
                break;
        }
    }, [data?.selectedDates?.orders, data?.selectedDates?.earnings, data?.selectedDates?.expenditure,]);

    const getData = () => {
        if (statsType === "orders") {
            return { ...data?.orderStats } || {};
        } else if (statsType === "expenditure") {
            const res = data?.customerExpenditure;
            return {
                'Chat': { label: "Chat", value: { Spent: (res?.Chat?.value?.Spent ?? 0)?.toFixed(2) } },
                'Voice Call': { label: "Voice Call", value: { Spent: (res?.VoiceCall?.value?.Spent ?? 0)?.toFixed(2) } },
                'Video Call': { label: "Video Call", value: { Spent: (res?.VideoCall?.value?.Spent ?? 0)?.toFixed(2) } },
            };
        }
        // }
        // Add more conditions as needed
        return null; // Default case
    }

    return (
        <Col className="col-12  d-flex flex-column" style={{ ...style }}>
            <Card className="py-2 pb-3 px-2 col-sm-12 flex-1" style={{ maxHeight: "fit-content" }}>
                <div className=" d-flex justify-content-between align-items-center">
                    <div className="my-2 ms-2  d-flex align-items-center">
                        <h1><b>{title}</b></h1>
                    </div>
                    <div className="font-size-14 my-2 d-flex align-items-center me-1">
                        <DateRange
                            allowClear={false}
                            defaultValue={[fromDate, tillDate]?.filter(date => date)?.map(date => moment(parseInt(date), 'YYYYMMDD'))}
                            disabled={[false, false]}
                            onChange={(dates) => {
                                const formattedFromDate = moment.unix(dates[0]).format('YYYYMMDD');
                                const formattedTillDate = moment.unix(dates[1]).format('YYYYMMDD');
                                if (statsType === "orders") {
                                    setData(prev => (
                                        {
                                            ...prev, selectedDates: {
                                                ...data?.selectedDates,
                                                orders: {
                                                    ...data?.orders,
                                                    fromDate: formattedFromDate,
                                                    tillDate: formattedTillDate
                                                }
                                            }
                                        }
                                    ));
                                } else if (statsType === "expenditure") {
                                    setData(prev => (
                                        {
                                            ...prev, selectedDates: {
                                                ...data?.selectedDates,
                                                expenditure: {
                                                    ...data?.expenditure,
                                                    fromDate: formattedFromDate,
                                                    tillDate: formattedTillDate
                                                }
                                            }
                                        }
                                    ));
                                }
                            }}
                        // onChange={(dates) => {
                        //     if (dates === null) {
                        //         setFromDate(null);
                        //         setTillDate(null);
                        //     } else {
                        //         const formattedFromDate = moment.unix(dates[0]).format('YYYYMMDD');
                        //         const formattedTillDate = moment.unix(dates[1]).format('YYYYMMDD');
                        //         onChange(formattedFromDate, formattedTillDate);
                        //     }
                        // }
                        // }
                        />
                    </div>
                </div>
                <Row className='mt-2 '>
                    <Col className={`col-12 d-flex flex-wrap gap-3`}>
                        <CountCard data={getData()} title={title} loading={loading} />
                    </Col>
                </Row >
            </Card >
        </Col >
    )
}

export default DashboardCard