import React, { useContext, useEffect, useState } from "react";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import ImageComponent from "../../components/Image/ImageComponent";
import FinanceService from "./FinanceService";
import { DialogContext } from "../../store/context/DialogContext";
import { Badge } from "reactstrap";
import moment from "moment";




const RecordComponent = ({ record }) => {
    const convertTimeStamp = (timeStamp) => {
        const date = new Date(timeStamp * 1000);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const formattedDateTime = ` ${day}-${month}-${year}, At ${hours}:${minutes}:${seconds}`;
        return formattedDateTime;
    }

    // const { showError } = useContext(DialogContext);



    // const [user, setUser] = useState(null);

    // const fetchUser = async () => {
    //     try {
    //         const response = await FinanceService.getUser(record?.user_id);
    //         setUser(response)
    //     } catch (e) {
    //         showError(e);
    //     }
    //     // setLoading(false);
    // };

    // useEffect(() => {
    //     fetchUser()
    // }, [user?.user_id])

    const getBackgroundColorClass = (status) => {
        switch (status) {
            case 1:
                return "bg-info"

            case 2:
                return "bg-success"

            case 3:
                return "bg-danger"

            default:
                return "bg-secondary"
        }
    }

    return (
        <Tr>
            <Td >
                <div className="d-flex align-items-center !important" >
                    <ImageComponent source={record?.profileImage} style={{
                        height: "35px",
                        width: "35px",
                        borderRadius: "35px"
                    }} />
                    <a
                        href={record?.userType === 1 ? `/consultant/payment/${record?.user_id}` : record?.userType === 2 ? `/client/payment/${record?.user_id}` : `/client/payment/${record?.user_id}`}
                        className="px-2 mt-2 blue-link"
                        style={{ color: 'blue', cursor: 'pointer' }}
                    >
                        {record?.userName ? record?.userName : '--'}
                    </a>
                </div>
            </Td>
            <Td >
                <div className="mt-2">
                    {moment.unix(record?.updatedAt ? record?.updatedAt : record?.at).format('DD-MM-YYYY h:mm:ss A')}
                </div>

            </Td>
            <Td >
                <div className="mt-2">
                    {record?.amount != null ? (record?.amount ?? 0?.toFixed(2)) + " AED" : "--"}
                </div>
            </Td>
            <Td >
                <div className="mt-2">
                    {record?.offerAmount != null ? (record?.offerAmount ?? 0)?.toFixed(2) + " AED" : "--"}
                </div>
            </Td>
            <Td className="text-center">
                <div className="mt-2">
                    {record?.userType === 1 ? "Consultant" : record?.userType === 2 ? "Customer" : record?.userType === 3 ? "Wishtok" : '--'}
                </div>
            </Td>
            <Td className="text-center">
                <div className="mt-2">
                    {record?.charge_id ? record?.charge_id : '--'}
                </div>

            </Td>
            <Td><div className="mt-2">
                {record?.msg}
            </div></Td>
            <Td>
                <div className="mt-2">
                    {record?.offerCode ? record?.offerCode : '--'}
                </div>
            </Td>
            <Td className="w-100 text-center" >
                <div className="mt-2">
                    <Badge className={`rounded-pill ${getBackgroundColorClass(record?.status)}`}>
                        {record?.status === 1 ? "Initiated" : (record?.status === 2 ? "Successful" : (record?.status === 3 ? "Failed" : record?.status === 10 ? "Manual" : '--'))}
                    </Badge>
                </div>
            </Td>
        </Tr>
    )
};

export default RecordComponent;
