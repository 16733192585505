export const ENDPOINTS = {
  grid: (page, rowPerPage, filter) => {
    let url = `/api/banners/grid?rowPerPage=` + rowPerPage + "&page=" + page
    return url
  },

  get: (id) => `/api/banners/get/${id}`,
  save: `/api/banners/add`,
  edit: (id) => `/api/banners/update/${id}`,

};