import React, { useContext, useEffect, useState } from 'react'
import Layout from "../../components/Layout/Layout"
import CategoryService from './CategoryService';
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { useNavigate } from 'react-router-dom';
import { BiSolidEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { DialogContext } from '../../store/context/DialogContext';
import { observer } from 'mobx-react-lite';

const Category = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { showConfirm, showMessage, showError } = useContext(DialogContext);

    const changePagination = () => {
        if (CategoryService.page != 1) {
            CategoryService.page = 1;
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            await CategoryService.fetch();
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [CategoryService.page, CategoryService.rows])

    useEffect(() => {
        const fetchServices = async () => {
            try {
                await CategoryService.setServiceCategoryMap();
            } catch (error) {
                console.log(error)
            }
        }

        fetchServices();
    }, [])

    const dotStyle = { height: 10, width: 10 };

    const onDelete = async (id, fromDetailPage = false) => {
        if (await showConfirm({
            title: "Delete Category?",
            description: "This is an unrecoverable operation.",
        })) {
            try {
                const response = await CategoryService.delete(id);
                if (response?.status === 200) {
                    showMessage("success", "Category Removed", "Success")
                    if(fromDetailPage) {
                        navigate("/category");
                    }
                    await fetchData();
                }
            } catch (e) {
                showError(e);
            }
        }
    }

    return (
        <>
            <Layout
                changePagination={changePagination}
                hideTitleBar={true}
                fetchData={(args) => {
                    fetchData({ ...args });
                }}
                onAddClick={() => {
                    navigate("/category/edit");
                }}
                gridLoading={loading}
                rows={CategoryService.rows}
                records={CategoryService.records}
                total={CategoryService.totalRecords}
                page={CategoryService.page}
                onPageChange={async (page, rows) => {
                    await CategoryService.onPaginationChange(page, rows);
                }}
                onDateChange={(title, fromDate, tillDate) => {
                    fetchData({ isDownload: false, fromDate, toDate: tillDate })
                }}
                addcontent="Category"
            >
                <Layout.TheadFull>
                    <Thead className="thead-dark">
                        <Tr>
                            <Th>{"Name"}</Th>
                            <Th>{"Is Active"}</Th>
                            <Th>{"Service Category"}</Th>
                            <Th>{"Color Code"}</Th>
                            <Th>{"Background Color Code"}</Th>
                            <Th>{"Position"}</Th>
                            <Th>{"Profession"}</Th>
                            <Th className="text-end">{"Actions"}</Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>

                <Layout.TbodyFull>
                    <Tbody>
                        {CategoryService.records?.map((record) => (
                            <Tr>
                                <Td>{record?.name}</Td>
                                <Td>{record?.active ? <div className='bg-success rounded-circle' style={dotStyle} /> : <div className='bg-danger rounded-circle' style={dotStyle} />}</Td>
                                <Td>{CategoryService.serviceCategoryMap[record?.serviceCategory]}</Td>
                                <Td>{record?.color_code ? <div style={{ backgroundColor: record?.color_code ?? "#FFF", height: 15, width: 15 }} /> : null}</Td>
                                <Td>{record?.background_color_code ? <div style={{ backgroundColor: record?.background_color_code ?? "#FFF", height: 15, width: 15 }} /> : null}</Td>
                                <Td>{record?.position}</Td>
                                <Td>{record?.profession}</Td>
                                <Td>
                                    <div className='d-flex align-items-center gap-3'>
                                        <div onClick={() => navigate(`/category/edit/${record?._id}`)}>
                                            <BiSolidEdit size={20} />
                                        </div>
                                        <div onClick={() => onDelete(record?._id)}>
                                            <MdDeleteOutline size={20} color="#FF0000" />
                                        </div>
                                    </div>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Layout.TbodyFull>
            </Layout >
        </>
    );
}

export default observer(Category)