import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Layout } from '../../components';
import FreeChatService from './FreeChatService';
import { DialogContext } from '../../store/context/DialogContext';
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { useNavigate } from 'react-router-dom';
import { formatSecondsToReadableFormat, TYPE_MAP } from './FreeChatConstant';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';

const FreeChat = () => {
    const [loading, setLoading] = useState(false);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const navigate = useNavigate();

    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await FreeChatService.fetch({});

        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    const deleteHandler = async (id) => {
        if (loading) return;
        try {
            if (await showConfirm({
                title: "Delete item?",
                description: "This is an unrecoverable operation.",
            })) {
                setLoading(true);
                const res = await FreeChatService.delete(id);
                if (res?.status === 200) {
                    showMessage("success", "Item deleted", "Success");
                    await fetchData();
                }
            }
        } catch (error) {
            showError(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [FreeChatService?.rows, FreeChatService?.page]);

    const changePagination = () => {
        if (FreeChatService.page != 1) {
            FreeChatService.page = 1;
        }
    }

    const Dot = ({ backgroundColor = '#00b800' }) => (<div style={{ borderRadius: 100, height: 10, width: 10, backgroundColor }} />)

    return (
        <Layout
            changePagination={changePagination}
            hideHeader={false}
            hideTitleBar={true}
            fetchData={fetchData}
            onAddClick={() => {
                navigate("/free-chat/edit");
            }}
            addcontent={"Free chat"}
            gridLoading={loading}
            rows={FreeChatService.rows}
            total={FreeChatService.totalRecords}
            page={FreeChatService.page}
            onPageChange={async (page, rows) => {
                await FreeChatService.onPaginationChange(page, rows);
            }}
        >
            <Layout.TheadFull>
                <Thead className="thead-dark">
                    <Tr>
                        <Th>Title</Th>
                        <Th>Description</Th>
                        <Th>Is Active</Th>
                        <Th>Duration</Th>
                        <Th>Type</Th>
                        <Th style={{ textAlign: "end" }}>Actions</Th>
                    </Tr>
                </Thead>
            </Layout.TheadFull>
            <Layout.TbodyFull>
                <Tbody>
                    {
                        FreeChatService.records?.map(e => {
                            return (
                                <Tr key={e?._id}>
                                    <Td>{e?.title}</Td>
                                    <Td>{e?.description}</Td>
                                    <Td>{<Dot backgroundColor={e?.isActive ? '#00b800' : "#F00"} />}</Td>
                                    <Td>{formatSecondsToReadableFormat(e?.duration)}</Td>
                                    <Td>{TYPE_MAP[e?.type]}</Td>
                                    <Td>
                                        <div className='d-flex align-items-center justify-content-end gap-3'>
                                            <div style={{ cursor: "pointer" }} onClick={() => deleteHandler(e?._id)}>
                                                <MdDeleteOutline color='#F00' size={22} />
                                            </div>
                                            <div style={{ cursor: "pointer" }} onClick={() => navigate(`/free-chat/edit/${e?._id}`)}>
                                                <FaRegEdit size={18} />
                                            </div>
                                        </div>
                                    </Td>
                                </Tr>
                            )
                        })
                    }
                </Tbody>
            </Layout.TbodyFull>
        </Layout>
    )
}

export default observer(FreeChat);