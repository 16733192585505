import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Row } from 'reactstrap';
import moment from 'moment';
import DateRange from '../DateSelects/DateRange';
import DashboardService from '../../pages/Dashboard/DashboardService';
import { DialogContext } from '../../store/context/DialogContext';
import CountCard from './CountCard';
import GeneralModal from '../Common/GeneralModal';

function ConsultantEarnings({ style }) {
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYYMMDD'))
    const [tillDate, setTillDate] = useState(moment().format('YYYYMMDD'))
    const { showError } = useContext(DialogContext);
    const [data, setData] = useState({});
    const [consultantBreakdown, setConsultantBreakdown] = useState({});
    const [showModal, setShowModal] = useState({ visible: false, type: "" });

    const mapping = {
        "": "",
        "chat": "Chat",
        "call": "Voice Call",
        "videoCall": "Video Call",
    }

    useEffect(() => {
        const getEarnings = async () => {
            try {
                setLoading(true);
                const resp = await DashboardService.totalConsultantEarnings({ fromDate, tillDate });
                setData(resp[0]);
            } catch (error) {
                showError(error)
            } finally {
                setLoading(false)
            }
        }

        getEarnings();
    }, [fromDate, tillDate])

    useEffect(() => {
        const arrangeData = async () => {
            const ids = data?.consultantBreakdown?.map(e => e?._id);
            await DashboardService.fetchConsultants(ids);

            const arranged = data?.consultantBreakdown?.reduce((acc, item) => {
                item.chatTypes.forEach(chat => {
                    const chatType = chat.chatType;
                    const typeName = {
                        1: 'chat',
                        2: 'call',
                        3: 'videoCall'
                    }[chatType];
                    if (!acc[typeName]) {
                        acc[typeName] = {};
                    }
                    acc[typeName][item._id] = {
                        totalAmount: (chat.totalAmount ?? 0)?.toFixed(2),
                        totalTransactions: chat.totalTransactions
                    };
                });
                return acc;
            }, {});

            setConsultantBreakdown(arranged)
        }

        arrangeData();
    }, [data])

    const getData = () => {
        if (!data?.totalByChatType?.[0]?.totalByChatType) return {}
        const res = data?.totalByChatType[0]?.totalByChatType
        return {
            "Chat": {
                label: "Chat",
                value: {
                    totalAmount: (res?.['1']?.totalAmount ?? 0)?.toFixed(2),
                    totalTransactions: res?.['1']?.totalTransactions ?? 0,
                },
                onClick: () => setShowModal({ visible: true, type: "chat" }),
            },
            "VoiceCall": {
                label: "Voice Call",
                value: {
                    totalAmount: (res?.['2']?.totalAmount ?? 0)?.toFixed(2),
                    totalTransactions: res?.['2']?.totalTransactions ?? 0,
                },
                onClick: () => setShowModal({ visible: true, type: "call" }),
            },
            "VideoCall": {
                label: "Video Call",
                value: {
                    totalAmount: (res?.['3']?.totalAmount ?? 0)?.toFixed(2),
                    totalTransactions: res?.['3']?.totalTransactions ?? 0,
                },
                onClick: () => setShowModal({ visible: true, type: "videoCall" }),
            },
        }
    }

    return (
        <Col className="col-12  d-flex flex-column" style={{ ...style }}>
            <Card className="py-2 pb-3 px-2 col-sm-12 flex-1" style={{ maxHeight: "fit-content" }}>
                <div className=" d-flex justify-content-between align-items-center">
                    <div className="my-2 ms-2  d-flex align-items-center">
                        <h1><b>Consultant Earnings</b></h1>
                    </div>
                    <div className="font-size-14 my-2 d-flex align-items-center me-1">
                        <DateRange
                            allowClear={false}
                            defaultValue={[fromDate, tillDate]?.filter(date => date)?.map(date => moment(parseInt(date), 'YYYYMMDD'))}
                            disabled={[false, false]}
                            onChange={(v) => {
                                if (v === null) {
                                    setFromDate(null);
                                    setTillDate(null);
                                } else {
                                    const formattedFromDate = moment.unix(v[0]).format('YYYYMMDD');
                                    const formattedTillDate = moment.unix(v[1]).format('YYYYMMDD');
                                    setFromDate(formattedFromDate)
                                    setTillDate(formattedTillDate)
                                }
                            }}
                        />
                    </div>
                </div>
                <Row className='mt-2 '>
                    <Col className={`col-12 d-flex flex-wrap gap-3`}>
                        <CountCard data={getData()} title={""} loading={loading} />
                    </Col>
                </Row >
            </Card >

            <GeneralModal
                visible={showModal?.visible}
                setVisible={() => setShowModal({ visible: false, type: "" })}
                contentStyle={{ width: "750px", }}
            >
                <h4 style={{ fontWeight: '600' }}>Category-Wise Breakdown ({mapping[showModal.type]})</h4>
                <table cellPadding={5} className='mt-4' style={{ border: '1px solid grey', width: '100%' }}>
                    <thead style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                        <tr>
                            <th>Name</th>
                            <th>Total Transactions</th>
                            <th>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(consultantBreakdown?.[showModal.type] ?? {}).map(([key, value], idx) => (
                            <tr key={idx} style={{ borderTop: '1px solid lightgrey' }}>
                                <td>{DashboardService.consultantMap?.[key] ?? ""}</td>
                                <td>{value?.totalTransactions}</td>
                                <td>{value?.totalAmount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </GeneralModal>
        </Col>
    )
}

export default ConsultantEarnings;