import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, Label, Row } from 'reactstrap'
import { InputField, SaveButton, SelectField } from '../../components';
import Checkbox from '../Consultant/Checkbox';
import { formatSecondsToReadableFormat, Types } from './FreeChatConstant';
import ConsultantSelect from "../../components/SelectField/ConsultantSelect/ConsultantSelect"
import { useNavigate, useParams } from 'react-router-dom';
import { DialogContext } from '../../store/context/DialogContext';
import FreeChatService from './FreeChatService';
import { toJS } from 'mobx';
import ImageComponent from '../../components/Image/ImageComponent';
import { doPOST } from '../../util/httpUtil';

const EditFreeChat = () => {
    const { id } = useParams();
    const [data, setData] = useState({ isActive: false, consultants: [] });
    const [loading, setLoading] = useState(false);
    const { showMessage, showError, showConfirm } = useContext(DialogContext);
    const navigate = useNavigate();

    const getDetails = async () => {
        try {
            setLoading(true);
            let response = { isActive: false, consultants: [] };
            if (id) response = await FreeChatService.detail(id);
            setData(response);
        } catch (error) {
            console.log(error);
            showError(error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getDetails();
        }
    }, [id]);

    const deleteHandler = async () => {
        if (loading) return;
        try {
            if (await showConfirm({
                title: "Delete item?",
                description: "This is an unrecoverable operation.",
            })) {
                setLoading(true);
                const res = await FreeChatService.delete(id);
                if (res?.status === 200) {
                    showMessage("success", "Item deleted", "Success");
                    navigate("/free-chat");
                }
            }
        } catch (error) {
            showError(error);
        }
        finally {
            setLoading(false);
        }
    }

    const onSubmitForm = async () => {
        try {
            if (!data?.title) {
                showError("Enter title");
                return;
            }
            if (!data?.description) {
                showError("Enter Description");
                return;
            }
            if ([null, undefined, 0, false, ""]?.includes(data?.duration)) {
                showError("Enter duration for chat");
                return;
            }
            if (!data?.type) {
                showError("Enter chat type");
                return;
            }
            setLoading(true);
            if (!id) { //  create
                const res = await FreeChatService.create(data);
                if (res?.status === 200) {
                    showMessage("success", "Free chat added", "Success");
                    navigate(`/free-chat/edit/${res?.data?.data?._id}`)
                }
                else {
                    showError(res?.data?.message);
                }
            } else { // update
                const res = await FreeChatService.edit(data);
                if (res?.status === 200) {
                    showMessage("success", "Free chat updated", "Success");
                    getDetails();
                }
                else {
                    showError(res?.data?.message);
                }
            }
        } catch (error) {
            console.log(error);
            showError(error);
        }
        finally {
            setLoading(false);
        }
    }

    const uploadBanner = async (e) => {
        if (!id) {
            showError("First Create a Free Chat to Upload Banner");
            return;
        }
        if (e.target?.files?.length > 0) {
            const file = e.target.files[0];
            const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
            if (!validImageTypes.includes(file.type)) {
                showError("Only image files (JPG, PNG, GIF, WEBP) are allowed.");
                return;
            }
            const formData = new FormData();
            formData.append("pic", file);
            formData.append("_id", id);
            formData.append("module", "free-chat");
            try {
                const response = await doPOST('/v1/api/file/upload', formData)
                setData({ ...data, bannerUrl: response?.data?.data?.[0] })
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    }

    return (
        <>
            <Card
                className="mt-10"
                style={{ height: "100%", overflow: "auto" }}
            >
                <CardBody className="py-3 px-5">
                    <Row>
                        <Col className='col-12 col-md-4'>
                            <InputField
                                label="Title"
                                placeholder="Title"
                                isRequired={true}
                                value={data?.title}
                                onChange={(title) => setData({ ...data, title })}
                                showLabel
                            />
                        </Col>
                        <Col className='col-12 col-md-4'>
                            <InputField
                                label="Description"
                                placeholder="Description"
                                value={data?.description}
                                onChange={(description) => setData({ ...data, description })}
                                showLabel
                                isRequired={true}
                            />
                        </Col>
                        <Col className='col-12 col-md-4 mt-4'>
                            <Checkbox
                                label="Is Active"
                                id="active"
                                checked={data?.isActive}
                                onClick={(v) => setData({ ...data, isActive: v.target.checked })}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col className='col-12 col-md-4'>
                            <InputField
                                style={{ flex: 1 }}
                                label={`Duration (In seconds) ${formatSecondsToReadableFormat(data?.duration)}`}
                                placeholder="Duration"
                                isRequired={true}
                                value={data?.duration}
                                onChange={(duration) => setData({ ...data, duration })}
                                showLabel
                                type="number"
                            />
                        </Col>
                        <Col className="ol-12 col-md-4">
                            <Label>Type<font color="#EC734B">*</font></Label>
                            <SelectField
                                data={Types}
                                placeholder="Type"
                                value={data?.type}
                                onChange={(v) => setData({ ...data, type: v.value })}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <ConsultantSelect
                                value={data?.consultants?.map(e => e?._id)}
                                onChange={(v) => {
                                    const consultants = v?.map(e => ({ _id: e?.value, category: e?.skill }));
                                    setData({ ...data, consultants });
                                }}
                                label='Select Consultants'
                                placeholder='Select Consultants'
                                isMulti
                            />
                        </Col>
                    </Row>

                    {id ? <Row className='mt-4'>
                        <Label className="me-4">Banner</Label>
                        <input
                            type="file"
                            name="myfile"
                            onChange={uploadBanner}
                        />

                        {data?.bannerUrl && <ImageComponent
                            type='default'
                            source={data?.bannerUrl}
                            style={{
                                height: "100px",
                                width: "250px",
                                marginTop: 20,
                            }}
                        />}
                    </Row> : null}

                    <div className='d-flex justify-content-end mt-3'>
                        {!!id ? <SaveButton
                            loading={loading}
                            onClick={deleteHandler}
                            label={"Delete"}
                            disabled={!loading ? false : true}
                            className="col-1"
                        /> : null}
                        <SaveButton
                            loading={loading}
                            style={{ right: "0%" }}
                            onClick={onSubmitForm}
                            label={id ? "Update" : "Save"}
                            disabled={data ? false : true}
                            className="col-1 ms-3"
                        />
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default observer(EditFreeChat);